<template>
  <v-container class="pa-0" fluid>
    <intro-image/>
    <carousel :items="usps" class="my-12"/>
    <section class="content">
      <!--      <rich-html :content="page.tekst"/>-->
      <!--eslint-disable-next-line-->
      <div v-html="page.tekst_2"/>
      <v-img :src="require('~/assets/img/index/over-ons.jpg')" alt="De Aardappelboer" :options="{threshold: .5}"
             class="image mt-sm-12"/>
    </section>
    <section class="content__family">
      <!--eslint-disable-next-line-->
      <div v-html="page.tekst_3"/>
      <div :class="{'btn': $breakpoint.is.smAndUp}" class="pl-3 pl-sm-0 text-sm-right mt-md-n12">
        <v-btn to="/over-ons" nuxt rounded small>Meer over ons</v-btn>
      </div>
    </section>
  </v-container>
</template>

<script>
import page from '~/plugins/mixins/page';
import Carousel from '~/components/Carousel';
import IntroImage from '~/components/IntroImage';

export default {
  components: {
    Carousel,
    IntroImage,
  },
  mixins: [page],
  async asyncData({$axios, error}) {
    try {
      const {data} = await $axios.get('usps');
      return {
        usps: data.data,
      };
    } catch (e) {
      error({statusCode: 503, message: e.message});
    }
  },
};
</script>

<style lang="sass" scoped>
.content
  ::v-deep .row
    .col-12
      display: flex
      flex-direction: column
      justify-content: center
      padding: 0
      align-self: stretch

      figure
        position: relative
        height: 100%

        img
          width: 100%
          height: 100%
          object-fit: cover
          position: relative
          z-index: 2

  &:nth-of-type(1)
    ::v-deep .row
      align-items: center

      &:nth-of-type(odd)
        margin-top: 70px

        figure
          &::before
            content: ''
            position: absolute
            z-index: 1
            top: -7%
            bottom: 0
            left: 0
            right: 7%
            background: var(--v-primary-lighten1)

          img
            padding-left: 4%

      &:nth-of-type(even)
        position: relative
        z-index: 3
        @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
          margin-bottom: 100px

        .col-12
          &:nth-of-type(1)
            order: 2
            @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
              order: 1

          &:nth-of-type(2)
            order: 3
            @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
              order: 2

          &:nth-of-type(3)
            order: 4

          &:nth-of-type(4)
            order: 1
            @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
              order: 4

          figure
            &::before
              content: ''
              position: absolute
              z-index: 1
              top: -7%
              bottom: 0
              left: 7%
              right: 0
              background: var(--v-primary-base)

            img
              padding-right: 4%

        &:nth-of-type(4)
          padding-right: 3%

  &__family
    position: relative
    z-index: 2
    @media screen and (min-width: map_get($grid-breakpoints, 'sm'))
      transform: translateY(-6%)
    @media screen and (min-width: map_get($grid-breakpoints, 'md'))
      transform: translateY(-16%)

    ::v-deep .row
      h2
        padding-bottom: 0 !important

      &:nth-of-type(2)
        .col-12
          padding: 0 12px

    .btn
      margin-right: calc(100% / 6)

.image
  position: relative

  &::before
    content: ''
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: url('~assets/img/bg2.png') center bottom no-repeat
    background-size: contain
</style>
