<template>
  <section v-if="$route.name !== 'index'" class="intro" v-html="page.tekst"/>
  <v-container v-else>
    <v-row class="mt-n22">
      <v-col cols="12" md="6" xl="4" offset-xl="1" class="intro__index">
        <img :src="require('~/assets/img/index/aardappels-ingrond.png')" alt="De Aardappelboer" class="d-none d-md-block">
        <img :src="require('~/assets/img/index/aardappel.png')" alt="De Aardappelboer" class="intro__index-img">
        <img :src="require('~/assets/img/index/aardappel-blur.png')" alt="De Aardappelboer" class="intro__index-img2">
      </v-col>
      <v-col offset-md="1" cols="12" md="5" xl="4" class="intro__index-text">
        <div v-html="page.tekst"/>
        <v-btn to="/frietaardappel" nuxt rounded small>
          Meer over frietaardappelen
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      isOnScreen: false,
    };
  },
  computed: {
    ...mapState(['page']),
  },
  methods: {
    onIntersect(entries) {
      this.isOnScreen = entries[0].isIntersecting;
    },
  },
};
</script>

<style lang="sass" scoped>
.trigger
  position: relative
  top: 32vh
  height: 10px
  padding: 0

.intro__index
  position: relative

  &-img
    position: absolute
    top: -14%
    right: 8%
    height: 60px
    transform: scale(-1, 1)
    @media screen and (min-width: map-get($grid-breakpoints, 'md'))
      left: 0
      height: 200px
      transform: none

  &-img2
    position: absolute
    top: -80%
    right: 30%
    height: 44px
    transform: scale(-1, 1)
    @media screen and (min-width: map-get($grid-breakpoints, 'md'))
      top: -40%
      left: 30%
      height: 180px
      transform: none

  &-text
    position: relative

    ::v-deep h2
      padding-bottom: 20px

.intro
  ::v-deep .row
    margin: 0 !important
    align-items: start

  &:nth-of-type(1)
    margin-bottom: 50px
</style>
