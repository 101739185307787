<template>
  <v-container fluid class="o-hidden">
    <v-row
      class="flex-nowrap my-12 ml-6 ml-sm-2"
      :style="{'transform' : `translateX(calc(-${position} * ((${cols}/12) * 100%)))`}"
    >
      <v-col
        v-for="(item, index) in items"
        :key="index"
        cols="11"
        sm="4"
        md="3"
        xl="2"
        :class="($breakpoint.is.xsOnly && index !== position) || ($breakpoint.is.smAndUp && index !== position + 1) ? 'opacity-5' : 'usp--active'"
        class="usp--item px-5"
      >
        <h3
          :class="($breakpoint.is.xsOnly && index !== position) || ($breakpoint.is.smAndUp && index !== position + 1) ? 'inactive--item heading--inactive': 'active--item pb-0 pb-sm-4 pt-1 pt-md-2'"
          class="margin--heading pl-sm-0"
        >
          {{ item.title }}
        </h3>
        <div :class="index !== position + 1 ? 'inactive--item pt-2 pt-lg-0': 'pt-0 pt-sm-6 pt-lg-3'">{{ item.description }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      position: 0,
    };
  },
  computed: {
    cols() {
      if (this.$breakpoint.is.xlOnly) {
        return 2;
      } else if (this.$breakpoint.is.smOnly) {
        return 4;
      } else if (this.$breakpoint.is.xsOnly) {
        return 11;
      } else {
        return 3;
      }
    },
  },
  created() {
    this.next();
  },
  methods: {
    next() {
      setInterval(() => {
        this.position += 1;
        this.position %= this.items.length;
        if (this.position > 0) {
          this.items.push(this.items[this.position - 1]);
        }
      }, 4000);
    },
  },
};
</script>

<style scoped lang="sass">
.active--item
  opacity: 1
  line-height: 32px

.margin--heading
  margin-right: 50%
  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    margin-right: 40%
  @media screen and (min-width: map-get($grid-breakpoints, 'md'))
    margin-right: 0

  .inactive--item
    opacity: 0.5

  .heading--inactive
    line-height: 42px

.usp--item
  position: relative
  transition: .5s

  &::before
    content: ''
    position: absolute
    top: -12px
    left: 0
    height: 106%
    width: 4px
    background-image: url('~assets/img/line.png')

  @media screen and (min-width: map-get($grid-breakpoints, 'lg'))
    h3
      padding-right: 80px

.usp--active
  transform: scale(1.05)
</style>
